import { Box, Typography, Button, Stack } from '@mui/material';
import React from 'react';
import ReactJson from 'react-json-view';

function renderSimple(row, key) {
  return (
    row[key] && (
      <Typography variant="body2" sx={{ lineHeight: 1.3 }}>
        {row[key]}
      </Typography>
    )
  );
}

function renderArray(row, key) {
  return (
    <Typography variant="body2" sx={{ textTransform: 'capitalize', lineHeight: 1.3 }}>
      {typeof row[key] === 'object' ? row[key].join(' ') : row[key]}
    </Typography>
  );
}

function renderObject(row, key) {
  const ruleKey = Object.keys(row.schemaRoot.properties)[0];

  const schemaProperties =
    row.schemaRoot.properties[ruleKey].items.properties[key].properties;

  return Object.entries(row[key]).map((elm, idx) => (
    <Box key={`json-${idx}`}>
      {typeof elm[1][0] === 'object' ? (
        <Box sx={{ width: '100%', mt: 1 }}>
          <ReactJson
            src={elm[1]}
            name="data"
            indentWidth={2}
            displayObjectSize={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            style={{ fontSize: 12 }}
          />
        </Box>
      ) : (
        <Stack
          key={`obj-${idx}`}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={1}
          width="inherit"
        >
          <Typography
            variant="body2"
            title={elm[0]}
            sx={{ lineHeight: '1.3', textTransform: 'capitalize' }}
          >
            {`${elm[0]
              .replace('arrivals', 'Arr')
              .replace('departures', 'Dep')
              .replace('arrival', 'Arr')
              .replace('departure', 'Dep')
              .replace('adult', 'ADT')
              .replace('child', 'CNN')
              .replace('infant', 'INF')}: `}
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: '1.3', maxWidth: '750px' }}>
            {typeof elm[1] === 'object' ? elm[1].join(' ') : String(elm[1])}
          </Typography>
        </Stack>
      )}
    </Box>
  ));
}

function renderDeparturesArrivalsGeneric(row, key) {
  if (row[key])
    return (
      <>
        {row[key].departures && (
          <Box title={`Deparures: ${row[key].departures}`} className="inline">
            <strong>De: </strong>
            {renderSplitArray(row[key], 'departures')}
          </Box>
        )}

        {row[key].arrivals && (
          <Box className="inline">
            <strong title="Arrivals">Ar: </strong>
            {renderSplitArray(row[key], 'arrivals')}
          </Box>
        )}
      </>
    );

  return '';
}
function renderDepartureArrivalGeneric(row, key) {
  if (typeof row[key] === 'object')
    return (
      <>
        {row[key].departure && (
          <Box title={`Deparure: ${row[key].departure}`} className="inline">
            <strong>De: </strong>
            {row[key].departure}
          </Box>
        )}

        {row[key].arrival && (
          <Box className="inline">
            <strong title="Arrival">Ar: </strong>
            {row[key].arrival}
          </Box>
        )}
      </>
    );

  return row[key];
}

function renderMaxPriceDifference(row, key) {
  if (row[key])
    return (
      <>
        {row[key].differentBaggage && (
          <Box className="inline">
            <strong>Different Baggage: </strong>
            {row[key].differentBaggage}
          </Box>
        )}

        {row[key].sameBaggage && (
          <Box className="inline">
            <strong>Same Baggage: </strong>
            {row[key].sameBaggage}
          </Box>
        )}
      </>
    );

  return '';
}
function renderFromToGeneric(row, key) {
  if (row[key])
    return (
      <>
        {row[key].from !== undefined && (
          <Box className="inline">
            <strong>Fr: </strong>
            {row[key].from}
          </Box>
        )}

        {row[key].to && (
          <Box className="inline">
            <strong>To: </strong>
            {row[key].to}
          </Box>
        )}
      </>
    );

  return '';
}
function renderMinMaxLengthGeneric(row, key) {
  if (row[key])
    return (
      <>
        {row[key].minLength && (
          <Box className="inline">
            <strong>Min: </strong>
            {row[key].minLength}
          </Box>
        )}

        {row[key].maxLength && (
          <Box className="inline">
            <strong>Max: </strong>
            {row[key].maxLength}
          </Box>
        )}
      </>
    );

  return '';
}

function renderSurchargeIn(row, key) {
  const obj = row[key];

  const renderAirlines = (nrs) => (
    <>
      <Box className="render-airlines">
        <Box className="render-airlines__trumpet">Separate if:</Box>
        {nrs.map((number, i) => (
          <span
            key={`seperate-${number}`}
            style={{
              marginRight: 4,
            }}
          >
            {number}
          </span>
        ))}
      </Box>
    </>
  );
  return (
    <Box style={{ fontSize: 14, lineHeight: 1.2 }}>
      {obj.hasOwnProperty('displayIn') && (
        <Box className="inline" style={{ textTransform: 'capitalize' }}>
          {obj.displayIn.toLowerCase()}
        </Box>
      )}
      {obj.hasOwnProperty('separateIf') &&
        renderAirlines(obj.separateIf.ticketingAirlines)}
    </Box>
  );
}

function renderException(row, key) {
  if (row[key]) {
    return (
      <Typography variant="body2" sx={{ color: 'n' }}>
        Click arrow to the left!
      </Typography>
    );
  }

  return '';
}

function renderSplitArray(row, key) {
  if (row[key]) {
    row[key].forEach((part, index, theArray) => {
      theArray[index] = `${part} `;
    });

    return row[key];
  }

  return '';
}

function renderSplitArrayWithLineBreak(row, key) {
  if (row[key]) {
    return (
      <Box>
        {row[key].map((item, i) => (
          <span key={i}>
            {item}
            <br />
          </span>
        ))}
      </Box>
    );
  }

  return '';
}

function renderJSONarray(row, key) {
  return (
    <>
      {row[key].map((obj, i) => (
        <ReactJson
          key={`json-${i}`}
          src={obj}
          name={obj.topLevelDomain}
          collapsed
          indentWidth={2}
          displayObjectSize={false}
          displayDataTypes={false}
          enableClipboard
          quotesOnKeys={false}
          style={{ fontSize: 12 }}
        />
      ))}
    </>
  );
}
function renderJSONobject(row, key) {
  const obj = row[key];
  return (
    <>
      <ReactJson
        src={obj}
        name={key}
        collapsed
        indentWidth={2}
        displayObjectSize={false}
        displayDataTypes={false}
        enableClipboard={false}
      />
    </>
  );
}

function renderNestedObject(row, key) {
  if (row[key]) {
    const obj = row[key];

    return (
      <Box>
        {Object.keys(obj).map((keyName, i) => (
          <Box className="input-label" key={i}>
            <strong>{keyName}:</strong> {String(obj[keyName])}
          </Box>
        ))}
      </Box>
    );
  }

  return '';
}

function renderCheckBox(row, key) {
  return <input type="checkbox" disabled checked={row[key]} />;
}
function renderDaysOnDates(row, key) {
  return (
    <Box>
      {row.daysOnDates.map((item, i) => (
        <Box
          key={`daysOnDates-${i}`}
          className="inline"
          style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '200px' }}
        >
          <Box>
            <strong>Date:</strong> {item.date}
          </Box>
          <Box>
            <strong>Days:</strong> {item.days}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export {
  renderSimple,
  renderArray,
  renderObject,
  renderDeparturesArrivalsGeneric,
  renderDepartureArrivalGeneric,
  renderFromToGeneric,
  renderMinMaxLengthGeneric,
  renderSurchargeIn,
  renderSplitArray,
  renderException,
  renderSplitArrayWithLineBreak,
  renderMaxPriceDifference,
  renderJSONarray,
  renderJSONobject,
  renderNestedObject,
  renderCheckBox,
  renderDaysOnDates,
};
